<template>
    <div class="projectWrap">
      <div class="title">Projects</div>
      <div class="content">
          <p>We support MetaNet-Related and open-source projects through funding, advocacy, research and collaborations.</p>
      </div>
      <div class="banner">
          <div class="item" @click.stop="toMetaID">
              <div class="image"><img src="../assets/img/img_1_metaid@2x.png" alt=""></div>
              <div class="contentWrap">
                  <div>MetaID</div>
                  <div>MetaID is a DID sub-protocol of Metanet.</div>
                  <div>
                     <div class="left"><span>Learn more about MetaID </span></div> 
                     <div class="right"><i class="iconfont icon-xiangyoujiantou"></i></div> 
                </div>
              </div>
          </div>
          <div class="item" @click.stop="toMetaProtocols">
              <div class="image"><img src="../assets/img/img_2_mp@2x.png" alt=""></div>
              <div class="contentWrap">
                  <div>MetaProtocols</div>
                  <div>Create and submit your protocol, or view the existing MetaID agreement to build your application.</div>
                  <div>
                     <div class="left"><span>Learn more about MetaProtocols</span></div> 
                      <div class="right"><i class="iconfont icon-xiangyoujiantou"></i></div> 
                    </div>
              </div>
          </div>
          <div class="item" @click.stop="toGitHub">
              <div class="image" ><img src="../assets/img/img_3_om@2x.png" alt=""></div>
              <div class="contentWrap">
                  <div>OpenMetaNet</div>
                  <div>Some open source MetaID projects on GitHub.</div>
                  <div>
                     <div class="left"><span>Learn more about OpenMetaNet</span></div> 
                      <div class="right"><i class="iconfont icon-xiangyoujiantou"></i></div>
                  </div>
              </div>
             
          </div>
      </div>
  
    <div class="footer">
        <div class="footerLeft">
          <div @click.stop="toMetaID">MetaID</div>
          <div @click.stop="toMetaProtocols">MetaProtocols</div>
          <div><a target="_blank" href="mailto:showpay@showpay.io">Contact us: metaid@metaid.io</a></div>
        </div>
        <div class="footerRight">
          <div >© 2021 Open MetaNet Foundation, All Rights Reserved.</div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'projects',
    methods:{
    toMetaID(){
      window.open('https://www.metaid.io')
    },
    toMetaProtocols(){
      window.open('https://www.metaprotocols.com')
    },
    toGitHub(){
        window.open('https://github.com/openmetanet')
    },
   
    }
}
</script>
<style lang="scss" scoped>
.projectWrap{
    // width: 80vw;
    // width: 100vw;
    // padding-left: 8rem /* 112/14 */;
    // padding-right: 8rem /* 112/14 */;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .title{
         width: 57.142857rem /* 800/14 */;
        padding-top: 11.428571rem /* 160/14 */;
        // padding-left: 21.285714rem /* 298/14 */;
        font-size: 2.571429rem /* 36/14 */;
        font-weight: Medium;
        font-family:PingFangSC-Semibold, sans-serif;
    }
    .content{
        // padding-left: 21.285714rem /* 298/14 */;
        width: 57.142857rem /* 800/14 */;
        p{
            font-family:PingFangSC-Regular, sans-serif;
            font-size: 1.714286rem /* 24/14 */;
            line-height: 1.5em;
             padding-top: 2.142857rem /* 30/14 */
        }
    }
    .banner{
        // padding-top: 7.142857rem /* 100/14 */;
        margin-top: 3.571429rem /* 50/14 */;
        // padding-left: 21.285714rem /* 298/14 */;
        width: 60vw;
        // height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .item{
            cursor: pointer;
            border-radius: .285714rem /* 4/14 */;
            background-color: rgba(49, 130, 249, 0.05);
            display: flex;
            width: 57.142857rem /* 800/14 */;
            height: 12.857143rem /* 180/14 */;
            margin-top: .714286rem /* 10/14 */;
            .image{
                border-radius: .285714rem /* 4/14 */;
                width: 17.142857rem /* 240/14 */;
                height: 10.714286rem /* 150/14 */;
                padding:1.071429rem /* 15/14 */;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        
                        
                    }
            }
            .contentWrap{
                display: flex;
                flex-direction: column;
                width: 34.642857rem /* 485/14 */;
                padding-left: 1.428571rem /* 20/14 */;
                div:nth-of-type(1){
                    font-size: 1.714286rem /* 24/14 */;
                    line-height: 5.142857rem /* 72/14 */;
                    font-family:PingFangSC-Semibold, sans-serif;
                }
                div:nth-of-type(2){
                    font-family:PingFangSC-Regular, sans-serif;
                    font-size: 1.142857rem /* 16/14 */;
                    line-height: 1.428571rem /* 20/14 */;
                }
                div:nth-of-type(3){
                   
                    font-size: 1.142857rem /* 16/14 */;
                    opacity: 0.3;
                    filter:alpha(opacity=30);
                    -moz-opacity:0.3;
                    -khtml-opacity:0.3;
                    line-height: 5.142857rem /* 72/14 */;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    &:hover{
                        color:  #3182F9;
                        opacity: 1;
                         filter:alpha(opacity=100);
                        -moz-opacity:1;
                        -khtml-opacity:1;
                    }
                    .left{
                         font-family:PingFangSC-Regular, sans-serif;
                    }
                    .right{
                        margin-top: .642857rem /* 9/14 */;
                        i{
                        font-size: 1.892857rem /* 26.5/14 */;
                    }
                    }
                    
                    
                }
            }
           
        }
    }
    .footer{
    // height: 5%;
    margin-top: 9.285714rem /* 130/14 */;
    width: 85vw;
    margin-bottom: .714286rem /* 10/14 */;
    // height: 5rem /* 70/14 */;
    line-height:1.5em;
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    align-items: center;
    // padding-left: 8rem /* 112/14 */;
    font-size: 1rem /* 14/14 */;
    font-family: PingFangSC-Regular, sans-serif;
//         font-weight: Regular;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      width: 30vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      justify-content: space-between;
      div{
        margin-right: 2.142857rem /* 30/14 */;
        cursor: pointer;
        &:hover{
          color:  #3182F9;
           opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
        }
        
      }
      div:nth-of-type(3){
        >a{
          &:hover{
              color:  #3182F9;
               opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
          }
        }
      }
    }
    .footerRight{
     
      cursor: pointer;
    }

  }
}
@media screen and(max-width:1500px){
    .projectWrap{
    // padding-top: 7.857143rem /* 110/14 */;
    width: 80vw;
    // height: 100%;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
    .footer{
    width: 80vw;
    line-height:1.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100vw;
    font-size: .928571rem /* 13/14 */;
    font-family: PingFangSC-Regular, sans-serif;
//         font-weight: Regular;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .footerRight{
      padding: 0px;
      cursor: pointer;
      div{
          font-size: .928571rem /* 13/14 */;
      }
    }
    }
    .title{
        padding-left: 7.142857rem /* 100/14 */;
        font-size: 2.571429rem /* 36/14 */;
        font-weight: Medium;
       
    }
    .content{
        padding-left: 7.142857rem /* 100/14 */;
        p{
            font-weight: Regular;
            font-size: 1.714286rem /* 24/14 */;
            line-height: 1.5em;
             padding-top: 2.142857rem /* 30/14 */
        }
    }
    .banner{
        
        // padding-top: 7.142857rem /* 100/14 */;
        padding-left: 7.142857rem /* 100/14 */;
        width: 60vw;
        // height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item{
            cursor: pointer;
            border-radius: .285714rem /* 4/14 */;
            background-color: rgba(49, 130, 249, 0.05);
            display: flex;
            width: 57.142857rem /* 800/14 */;
            height: 12.857143rem /* 180/14 */;
            margin-top: .714286rem /* 10/14 */;
            .image{
                border-radius: .285714rem /* 4/14 */;
                width: 17.142857rem /* 240/14 */;
                height: 10.714286rem /* 150/14 */;
                padding:1.071429rem /* 15/14 */;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        
                        
                    }
            }
            .contentWrap{
                display: flex;
                flex-direction: column;
                width: 34.642857rem /* 485/14 */;
                padding-left: 1.428571rem /* 20/14 */;
                div:nth-of-type(1){
                    font-size: 1.714286rem /* 24/14 */;
                    line-height: 5.142857rem /* 72/14 */;
                }
                div:nth-of-type(2){
                    font-size:1.142857rem /* 16/14 */;
                    line-height: 1.428571rem /* 20/14 */;
                }
                div:nth-of-type(3){
                    font-size: 1.142857rem /* 16/14 */;
                    opacity: 0.3;
                    filter:alpha(opacity=30);
                    -moz-opacity:0.3;
                    -khtml-opacity:0.3;
                    line-height: 5.142857rem /* 72/14 */;
                }
            }
           
        }
     
    
    }
}
}
@media screen and(max-width:1336px){
  .projectWrap{
    // padding-top: 7.857143rem /* 110/14 */;
    width: 80vw;
    // height: 100%;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
     .footer{
        width: 100%;
    }
    .title{
      
        padding-left: 0;
        font-size: 2.571429rem /* 36/14 */;
        font-weight: Medium;
    }
    .content{
        padding-left: 0;
        p{
            font-weight: Regular;
            font-size: 1.714286rem /* 24/14 */;
            line-height: 1.5em;
             padding-top: 2.142857rem /* 30/14 */
        }
    }
    .banner{
        // padding-top: 7.142857rem /* 100/14 */;
       
        padding-left: 0;
        width: 60vw;
        // height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item{
            cursor: pointer;
            border-radius: .285714rem /* 4/14 */;
            background-color: rgba(49, 130, 249, 0.05);
            display: flex;
            width: 57.142857rem /* 800/14 */;
            height: 12.857143rem /* 180/14 */;
            margin-top: .714286rem /* 10/14 */;
            .image{
                border-radius: .285714rem /* 4/14 */;
                width: 17.142857rem /* 240/14 */;
                height: 10.714286rem /* 150/14 */;
                padding:1.071429rem /* 15/14 */;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        
                        
                    }
            }
            .contentWrap{
                display: flex;
                flex-direction: column;
                width: 34.642857rem /* 485/14 */;
                padding-left: 1.428571rem /* 20/14 */;
                div:nth-of-type(1){
                    font-size: 1.714286rem /* 24/14 */;
                    line-height: 5.142857rem /* 72/14 */;
                }
                div:nth-of-type(2){
                    font-size:1.142857rem /* 16/14 */;
                    line-height: 1.142857rem /* 16/14 */;
                }
                div:nth-of-type(3){
                    font-size: 1rem /* 14/14 */;
                    opacity: 0.3;
                    line-height: 5.142857rem /* 72/14 */;
                }
            }
           
        }
     
    
    }
}
}
@media screen and(max-width:980px){
  .projectWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
        width: 35.714286rem /* 500/14 */;
      }
      .content{
          width: 35.714286rem /* 500/14 */;
      }
       .title{
           
        }
    .banner{
        width: 30vw;
        // height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item{
            cursor: pointer;
            border-radius: .285714rem /* 4/14 */;
            background-color: rgba(49, 130, 249, 0.05);
            display: flex;
            flex-direction: column;
            width: 35.714286rem /* 500/14 */;
            height: 23.571429rem /* 330/14 */;
            margin-top: .714286rem /* 10/14 */;
            .contentWrap{
                display: flex;
                flex-direction: column;
                width: 34.642857rem /* 485/14 */;
                margin-top: -1.428571rem /* 20/14 */;
            }  
        }
    }
}  
}
@media screen and (max-width:768px) {
    .projectWrap{
        padding-left: 2.142857rem /* 30/14 */;
       
    }
}
@media screen and (max-width:500px){
    .projectWrap{
    padding-top: 4.285714rem /* 60/14 */;
    // margin-top: 1.428571rem /* 20/14 */;
    padding-left:0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 100vw;
    .footer{
        margin-left:.714286rem /* 10/14 */ ;
        line-height:1.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100vw;
    font-size: .928571rem /* 13/14 */;
    font-family: PingFangSC-Regular, sans-serif;
//         font-weight: Regular;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      display: flex;
      flex-direction: row;
    //   flex-wrap: wrap;
    //   justify-content: flex-start;
    }
    .footerRight{
      padding: 0px;
      cursor: pointer;
      div{
          font-size: .928571rem /* 13/14 */;
      }
    }
    }
    .title{
        font-size:2.142857rem /* 30/14 */;
        font-weight: Medium;
        padding-left: .714286rem /* 10/14 */;
        padding-top: 3.571429rem /* 50/14 */;
    }
    .content{
        padding-left: .714286rem /* 10/14 */;
        max-width: 90vw;
        p{
            font-weight: Regular;
            font-size:1.285714rem /* 18/14 */;
            line-height: 1.5em;
             padding-top: 2.142857rem /* 30/14 */
        }
    }
    .banner{
        margin-top:1.5rem;
        width: 100vw;
        // height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item{
            width: 100vw;
            cursor: pointer;
            border-radius: .285714rem /* 4/14 */;
            background-color: rgba(49, 130, 249, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
             padding-left:0;
            height: auto;
            margin-top: .714286rem /* 10/14 */;
            .image{
                max-width: 100vw;
                border-radius: .285714rem /* 4/14 */;
                margin-left: 0;
                height: 10.714286rem /* 150/14 */;
               
                    img{
                        
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
            }
            .contentWrap{
                display: flex;
                flex-direction: column;
                padding-left: 0;
                max-width: 100vw;
                align-items: center;
                text-align: center;
                div:nth-of-type(1){
                    font-size: 1.714286rem /* 24/14 */;
                    line-height: 5.142857rem /* 72/14 */;
                 
                }
                div:nth-of-type(2){
                    font-size: 1.142857rem /* 16/14 */;
                    line-height: 1.428571rem /* 20/14 */;
                    max-width: 90vw;
                }
                div:nth-of-type(3){
                    font-size: 1.142857rem /* 16/14 */;
                    opacity: 0.3;
                    line-height: 5.142857rem /* 72/14 */;
                    
                }
            }
           
        }
     
    
    }
}
}
@media screen and(max-width:320px){
    .projectWrap{
        .banner{
        .item{
            .image{
                margin-left: 0;
            }
        }
        }
    }
}
</style>